import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTMarkup RotateAppearance:]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget GetOptions]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget ReplaceOptions:]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget RemoveOption:]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget GetOptions]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget ReplaceOptions:]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget RemoveOption:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotTypes.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypePencilDrawing</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNamePencilDrawing</div>
<div class="difference"><span class="status added">Added</span> PTPencilDrawingAnnotationIdentifier</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotEditTool.selectedAnnotations</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDigSigView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTDigSigView.m_dig_sig_points</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDigSigView.points</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.moreItemsButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.moreItemsViewController</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTEditToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTEditToolbar.styleButtonHidden</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFloatingSigViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFloatingSigViewController.digSigView</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTMoreItemsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTMoreItemsViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTMoreItemsViewController initWithToolManager:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPencilDrawingCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPencilDrawingCreate</div>
<div class="difference"><span class="status added">Added</span> -[PTPencilDrawingCreate commitAnnotation]</div>
<div class="difference"><span class="status added">Added</span> -[PTPencilDrawingCreate editAnnotation:onPage:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPencilDrawingCreate cancelEditingAnnotation]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSignatureAnnotationOptions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTSignatureAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTSignatureAnnotationOptions.canEditAppearance</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTextMarkupAnnotationOptions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTextMarkupAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTTextMarkupAnnotationOptions.copiesAnnotatedTextToContents</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolManager.pencilDrawingAnnotationOptions</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTToolManager.highlightAnnotationOptions</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) PTAnnotationOptions *highlightAnnotationOptions</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nonnull) PTTextMarkupAnnotationOptions *highlightAnnotationOptions</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTToolManager.underlineAnnotationOptions</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) PTAnnotationOptions *underlineAnnotationOptions</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nonnull) PTTextMarkupAnnotationOptions *underlineAnnotationOptions</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTToolManager.squigglyAnnotationOptions</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) PTAnnotationOptions *squigglyAnnotationOptions</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nonnull) PTTextMarkupAnnotationOptions *squigglyAnnotationOptions</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTToolManager.strikeOutAnnotationOptions</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) PTAnnotationOptions *strikeOutAnnotationOptions</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nonnull) PTTextMarkupAnnotationOptions *strikeOutAnnotationOptions</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTToolManager.signatureAnnotationOptions</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) PTAnnotationOptions *signatureAnnotationOptions</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nonnull) PTSignatureAnnotationOptions *signatureAnnotationOptions</td></tr>
</table>
<br>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}